'use strict';

(function() {
  class AddEditFindingCtrl {
    constructor($scope, Auth, $uibModal, $state, usSpinnerService) {
      this.$scope = $scope;
      this.$uibModal = $uibModal;
      this.blade = {};
      this.loading = false;
      this.usSpinnerService = usSpinnerService;
      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          // this.init();
        } else {
          $state.go('login');
        }
      });
    }

    addFinding() {
      return finding=> {
        // this.$scope.findings.unshift(finding.data);
        console.log(finding);
        this.$scope.$emit('addEditFindingCtrl', {refresh: true});
      }
    }

    openAddFindingModal(data = {}, update = false) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/finding/addEditFindingModal/addEditFindingModal.html',
        controller: 'AddEditFindingModalCtrl',
        controllerAs: 'aEFinding',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Finding',
            blade: this.$scope.blade
          }
        }
      });
      modalInstance.result.then(this.addFinding());
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditFindingCtrl', AddEditFindingCtrl);
})();
